@import url(https://rsms.me/inter/inter.css);
* {
  box-sizing: border-box;
}

html,
body,
#root,
.scroll {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

.icon {
  width: 3vw;
  margin-left: 1vw;
  height: auto;
}

.icon:hover {
  color: aqua;
}

.lg-icons {
  display: block;
  margin-left: 2vw;
}

.scroll {
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  -ms-scroll-snap-type: y proximity;
      scroll-snap-type: y proximity;
  scroll-behavior: smooth;
}

.scroll > div {
  scroll-snap-align: start;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: "Inter var", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.caption {
  pointer-events: none;
  position: fixed;
  top: 5vh;
  right: 5vw;
  color: rgb(7, 7, 8);
  font-size: 2em;
  font-weight: 200;
  line-height: 1em;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: auto;
}

.dot {
  pointer-events: none;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: inline-block;
  max-width: 600px;
  padding: 0;
  padding: 80px;
  color: rgb(133, 133, 151);
  line-height: 1.6em;
  font-size: 15px;
  font-weight: 800;
  letter-spacing: 1.5px;
}

.dot > li {
  font-weight: 600;
  color: rgb(133, 133, 151);
  list-style-image: url();
}

#dotP {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  padding: 0.75vw;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  border-radius: 15px;
  display: block;
  font-size: 1vw;
  font-weight: 600;
  z-index: 999;
}

.elsewhere {
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
  z-index: 999;
}

#name {
  font-weight: 800;
  font-size: 3vw;
}

.dot > h1 {
  -webkit-font-smoothing: auto;
  pointer-events: none;
  color: rgb(177, 98, 34);
  font-weight: 300;
  line-height: 1em;
  margin: 0;
  margin-bottom: 0.25em;
}

@media only screen and (max-width: 1000px) {
  #dotP {
    font-size: 1vw;
    color: "#000";
  }
  #name {
    font-size: 3vw;
    color: "#fff";
  }
  .caption {
    font-size: 4em;
  }
  .dot {
    padding: 10%;
  }
}

@media only screen and (max-width: 800px) {
  #dotP {
    font-size: 1vw;
    color: "#000";
  }
  #name {
    font-size: 3vw;
    color: "#fff";
  }
  .caption {
    font-size: 3em;
  }
  .dot > h1 {
    font-size: 3em;
  }
  .dot {
    padding: 10%;
  }
}

@media only screen and (max-width: 700px) {
  #dotP {
    font-size: 1vw;
    color: "#000";
  }
  #name {
    font-size: 3vw;
    color: "#fff";
  }
  .caption {
    font-size: 2em;
  }
  .dot > h1 {
    font-size: 3em;
  }
  .dot {
    padding: 10%;
  }
}

@media only screen and (max-width: 600px) {
  .beforeInfo {
    height: "120vh" !important;
  }
  #dotP {
    font-size: 4vw;
    color: "#000";
    padding: 2vw;
  }
  #name {
    font-size: 4vh;
    color: "#fff";
  }
  .caption {
    font-size: 1em;
  }
  .dot > h1 {
    font-size: 3em;
  }
  .dot {
    padding: 10%;
  }
}

@media only screen and (max-width: 428px) {
  .beforeInfo {
    height: "120vh" !important;
  }
  #dotP {
    font-size: 4vw;
    color: "#000";
    padding: 2vw;
  }
  #name {
    font-size: 4vh;
    color: "#fff";
  }
  .caption {
    font-size: 1em;
  }
  .dot > h1 {
    font-size: 3em;
  }
  .dot {
    padding: 10%;
  }
}

@media only screen and (max-width: 375px) {
  .beforeInfo {
    height: "120vh" !important;
  }
  #dotP {
    font-size: 4vw;
    color: "#000";
    padding: 2vw;
  }
  #name {
    font-size: 4vh;
    color: "#fff";
  }
  .caption {
    font-size: 1em;
  }
  .dot > h1 {
    font-size: 2.5em;
  }
  .dot {
    padding: 10%;
  }
}

